<template>
    <b-row class="justify-content-center">
        <b-col md="12" lg="12" xl="10">
        <CCard>
            <validation-observer ref="observer" v-slot="{ handleSubmit }">
                <b-form @submit.stop.prevent="handleSubmit(submit)">
                    <CCardHeader class="d-flex justify-content-between">
                        <h4 class="m-0">{{ $t('pages.vdi.images.headers.edit') }}</h4>
                    </CCardHeader>
                    <CCardBody>
                        <b-overlay :show="loading" id="overlay-background" variant="white" opacity="0.85" blur="2px">
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="6"
                                    label-cols-lg="5"
                                >
                                    <template #label>
                                        {{ $t('pages.vdi.pools.form.image') }}
                                    </template>
                                    <b-form-input
                                        v-model="form.vCenterImageName"
                                        disabled
                                    ></b-form-input>
                                </b-form-group>
                            <div>
                                <validation-provider
                                    :name="$t('pages.vdi.images.form.name').toString()"
                                    :rules="{ required: true, min: 3, max: 32 }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                        :label="$t('pages.vdi.images.form.name')"
                                    >
                                        <b-form-input
                                            type="text"
                                            name="name"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.name"
                                            aria-describedby="name-feedback"
                                        />
                                        <b-form-invalid-feedback id="name-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <validation-provider
                                    :name="$t('pages.vdi.images.form.description').toString()"
                                    :rules="{ required: true, min: 3, max: 512 }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                    >
                                        <template #label>
                                            {{ $t('pages.vdi.images.form.description') }}
                                        </template>
                                        <b-form-textarea
                                            name="description"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.description"
                                            aria-describedby="description-feedback"
                                            rows="2"
                                            max-rows="5"
                                        ></b-form-textarea>
                                        <b-form-invalid-feedback id="description-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <b-form-group
                                    label-cols-sm="12"
                                    label-cols-md="6"
                                    label-cols-lg="5"
                                >
                                    <template #label>
                                        {{ $t('pages.vdi.images.form.os') }}
                                    </template>
                                    <b-form-select v-model="form.os">
                                        <b-form-select-option value="linux">Linux</b-form-select-option>
                                        <b-form-select-option value="windows">Windows</b-form-select-option>
                                    </b-form-select>
                                </b-form-group>
                                <validation-provider
                                    :name="$t('pages.vdi.images.form.username').toString()"
                                    :rules="{ required: true, min: 3, max: 32 }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                        :label="$t('pages.vdi.images.form.username')"
                                    >
                                        <b-form-input
                                            type="text"
                                            name="username"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.userName"
                                            aria-describedby="username-feedback"
                                        />
                                        <b-form-invalid-feedback id="username-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                                <validation-provider
                                    :name="$t('pages.vdi.images.form.password').toString()"
                                    :rules="{ required: true }"
                                    v-slot="validationContext"
                                >
                                    <b-form-group
                                        label-cols-sm="12"
                                        label-cols-md="6"
                                        label-cols-lg="5"
                                        :label="$t('pages.vdi.images.form.password')"
                                    >
                                        <b-form-input
                                            type="text"
                                            name="password"
                                            :state="getValidationState(validationContext)"
                                            v-model="form.password"
                                            aria-describedby="password-feedback"
                                        />
                                        <b-form-invalid-feedback id="password-feedback">{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                    </b-form-group>
                                </validation-provider>
                            </div>
                        </b-overlay>
                    </CCardBody>
                    <CCardFooter class="d-flex justify-content-end">
                        <b-button type="submit" variant="orange">{{ $t('buttons.save') }}</b-button>
                    </CCardFooter>
                </b-form>
            </validation-observer>
        </CCard>
        </b-col>
    </b-row>
</template>

<script>
import imagesService from "../../../services/imagesService";

export default {
    name: "AppsEdit",
    props: ["id"],
    data: () => ({
        form: {
            name: '',
            description: '',
            vCenterImageRef: null,
            vCenterImageName: null,
            os: false,
            userName: '',
            password: '',
        },
        loading: false
    }),
    mounted() {
        this.load();
    },
    methods: {
        load(){
            imagesService.get(this.id).then(response => {
                this.form = response.data;
            }).catch();
        },
        getLocale(){
            return localStorage.getItem("locale");
        },
        getValidationState({dirty, validated, valid = null}) {
            return dirty || validated ? valid : null;
        },
        submit() {
            if (!this.loading) {
                this.loading = true;
                let data = Object.assign({}, this.form)
                imagesService.edit(data).then(() => {
                    this.$toastr.s(this.$t('pages.vdi.images.messages.success.edited'));
                    this.$router.push({name: 'VdiImages'})
                    this.loading = false;
                }).catch(error => {
                    this.errorHandler(error)
                    this.loading = false;
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
